import React, { useState, useEffect } from 'react';
import { Modal, TextInput, Button } from '@mantine/core';
import { useCookies } from 'react-cookie';

function LoginModal() {
  const [opened, setOpened] = useState(true);
  const [input, setInput] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['user-auth']);

  useEffect(() => {
    if (cookies['user-auth']) {
      setOpened(false);
    }
  }, [cookies]);

  const handleSubmit = () => {
    const staticValue = "H@rsh9099";  
    if (input === staticValue) {
      setCookie('user-auth', true, { path: '/', maxAge: 86400 }); 
      setOpened(false); 
    } else {
      alert("Wrong input! Try again.");
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={()=>{
        console.log()
      }}
      title="Please Enter Code"
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      centered
    >
      <TextInput
        placeholder="Enter key here"
        value={input}
        onChange={(event) => setInput(event.target.value)}
      />
      <Button onClick={handleSubmit} style={{ marginTop: 20 }}>
        Submit
      </Button>
    </Modal>
  );
}

export default LoginModal;
