import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  AppBar,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import { Select, TextInput, Group, Button, Image, Paper, Grid, Table, Tooltip, ScrollArea, Text } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import { SiWhatsapp } from "react-icons/si";
import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";
import Logo from "./final.svg";
import toast, { Toaster } from 'react-hot-toast';
import EmployeeModal from './EmployeeModal';
import { MdOutlineEmail } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginBottom: theme.spacing(1),
  },
  logo: {
    maxWidth: 50,
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  greenBox: {
    backgroundColor: "green",
    color: "white",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },

}));

function App() {
  const currentMonth = moment().format('YYYY-MM');
  const classes = useStyles();
  const [salaries, setSalaries] = useState([]);
  const [errors, setErrors] = useState({});
  const [filteredSalaries, setFilteredSalaries] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedSalary, setSelectedSalary] = useState(null);
  const baseUrl = process.env.REACT_APP_HOSTING_URL;
  useEffect(() => {
    fetchSalaries();
  }, []);

  useEffect(() => {
    filterSalariesByMonth(selectedMonth);
  }, [selectedMonth, salaries]);

  const fetchSalaries = async () => {
    const { data } = await axios.get(`${baseUrl}/salaries`);

    setSalaries(data);
    setFilteredSalaries(data);
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };

  const filterSalariesByMonth = (month) => {
    if (!month) {
      setFilteredSalaries(salaries);
    } else {
      const filtered = salaries.filter((salary) => moment(salary.month).format('YYYY-MM') === month);
      setFilteredSalaries(filtered);
    }
  };

  const monthOptions = salaries.reduce((acc, salary) => {
    const formattedMonth = moment(salary.month).format('YYYY-MM');
    if (!acc.some(option => option.value === formattedMonth)) {
      acc.push({ label: moment(salary.month).format('MMMM YYYY'), value: formattedMonth });
    }
    return acc;
  }, []);

  const handleSelectSalary = (salary) => {
    setSelectedSalary(salary);
    setFormData({
      _id: salary._id,
      name: salary.name,
      email: salary.email,
      month: moment(salary.month).format("YYYY-MM-DD"), // for date input compatibility
      basicSalary: salary.basicSalary,
      workingDays: salary.workingDays,
      presentFullDays: salary.presentFullDays,
      presentHalfDays: salary.presentHalfDays,
      totalPresentDays: salary.totalPresentDays,
      salaryPerDay: salary.salaryPerDay,
      advancedSalary: salary.advancedSalary,
      incentive: salary.incentive || 0,
      mobileNumber: salary.mobileNumber,
      finalSalary: calculateFinalSalary(),
      employeeId: salary.employeeId,
    });
    setSelectedEmployeeId(salary.employeeId);
  };

  const [formData, setFormData] = useState({
    _id: null,
    name: "",
    month: new Date(),
    email: "",
    basicSalary: null,
    workingDays: null,
    presentFullDays: null,
    presentHalfDays: null,
    totalPresentDays: null,
    salaryPerDay: null,
    advancedSalary: null,
    incentive: null,
    mobileNumber: "",
    finalSalary: '',
  });
  const calculateFinalSalary = () => {
    if (formData.totalPresentDays > 0) {
      const fsalary = formData.salaryPerDay * formData.totalPresentDays +
        parseInt(formData.incentive) -
        parseInt(formData.advancedSalary)
      return fsalary || '';
    }
  }
  console.log(formData);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    let newFormData = { ...formData, [name]: value };
    // Automatically update Total Present Days when Present Full or Half Days change
    if (name === "presentFullDays" || name === "presentHalfDays") {
      newFormData.totalPresentDays = Math.round(
        parseFloat(newFormData.presentFullDays) +
        parseFloat(newFormData.presentHalfDays) / 2
      );
    }
    newFormData.salaryPerDay = calculateSalaryPerDay();
    newFormData.finalSalary = calculateFinalSalary()

    setErrors({
      ...errors,
      [name]: "",
    });
    setFormData(newFormData);
  };

  const handleEdit = (salary) => {
    console.log(salary);
    setFormData({ ...salary });
    setSelectedSalary(salary);
  };

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "Name is required";
    tempErrors.month = formData.month ? "" : "Month is required";
    tempErrors.basicSalary = formData.basicSalary ? "" : "Basic Salary is required";
    tempErrors.workingDays = formData.workingDays ? "" : "Working Days are required";
    tempErrors.presentFullDays = formData.presentFullDays ? "" : "Present Full Days are required";
    tempErrors.presentHalfDays = formData.presentHalfDays ? "" : "Present Half Days are required";
    tempErrors.mobileNumber = formData.mobileNumber ? "" : "Mobile Number is required";

    setErrors({
      ...tempErrors
    });

    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const salaryDetails = {
      ...formData,
      finalSalary: calculateFinalSalary(),
      presentFullDays: parseInt(formData.presentFullDays),
      presentHalfDays: parseInt(formData.presentHalfDays),
    };

    const method = formData._id ? "put" : "post";
    const url = formData._id
      ? `${baseUrl}/salaries/${formData._id}`
      : `${baseUrl}/salaries`;

    try {
      await axios[method](url, salaryDetails);
      fetchSalaries();
      toast.success('Salary details saved successfully!');
      setFormData({
        _id: null,
        name: "",
        month: new Date(),
        email: "",
        finalSalary: calculateFinalSalary(),
        basicSalary: 0,
        workingDays: 0,
        presentFullDays: 0,
        presentHalfDays: 0,
        totalPresentDays: 0,
        salaryPerDay: 0,
        advancedSalary: 0,
        incentive: 0,
        mobileNumber: "",
      });
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }

  };

  const handleDelete = async (id) => {
    await axios.delete(`${baseUrl}/salaries/${id}`);
    toast.success('Salary deleted successfully!');
    fetchSalaries();
    if (selectedSalary && selectedSalary._id === id) {
      setSelectedSalary(null);
    }
  };

  const calculateSalaryPerDay = () => {
    if (formData.workingDays > 0) {
      return (formData.basicSalary / formData.workingDays).toFixed(2);
    }
    return 0;
  };



  const handleCalculateTotalPresentDays = () => {
    const totalPresentDays =
      parseFloat(formData.presentFullDays) +
      parseFloat(formData.presentHalfDays) / 2;
    return totalPresentDays;
  };
  const generateMessage = (salary) => {
    if (!salary) return "Select a salary to see the message.";

    // Constructing the message with a conditional incentive display
    let message = `
Hello ${salary.name} 👋,

Payslip for Month: ${moment(salary.month).format("MMMM YYYY")}
Your Salary is: ${salary.basicSalary}

Working Days: ${salary.workingDays}
Present Full Days: ${salary.presentFullDays}
Present Half Days: ${salary.presentHalfDays}
Total Present Days: ${salary.totalPresentDays}
Salary per Day: ${Math.round(salary.salaryPerDay)}

------------------------
Final Salary: ${Math.round(salary.finalSalary)}\n`;

    if (salary.advancedSalary > 0) {
      message += `Advanced Salary: ${salary.advancedSalary}\n`;
    }
    if (salary.incentive > 0) {
      message += `Incentive: ${salary.incentive}\n`;
    }

    message += `------------------------

NOTE : Please feel free to ask if you have any query ASAP.

Thank you,
Deltabits Management`;

    return message;
  };


  const handleWhatsAppSend = (salary) => {
    // Using the updated message generation logic with conditional incentive
    let message = `
Hello ${salary.name} 👋,

Payslip for Month: ${moment(salary.month).format("MMMM YYYY")}
Your Salary is: ${salary.basicSalary}

Working Days: ${salary.workingDays}
Present Full Days: ${salary.presentFullDays}
Present Half Days: ${salary.presentHalfDays}
Total Present Days: ${salary.totalPresentDays}
Salary per Day: ${Math.round(salary.salaryPerDay)}

------------------------
Final Salary: ${Math.round(salary.finalSalary)}\n`;

    if (salary.advancedSalary > 0) {
      message += `Advanced Salary: ${salary.advancedSalary}\n`;
    }
    if (salary.incentive > 0) {
      message += `Incentive: ${salary.incentive}\n`;
    }

    message += `------------------------

NOTE : Please feel free to ask if you have any query ASAP.

Thank you,
Deltabits Management`;

    const whatsappUrl = `https://web.whatsapp.com/send?phone=${salary.mobileNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };


  const [employees, setEmployees] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [employeeFormData, setEmployeeFormData] = useState({
    _id: null,
    name: "",
    basicSalary: "",
    workDays: ""
  });

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/employees`);
      setEmployees(data);
    } catch (error) {
      toast.error('Failed to fetch employees');
    }
  };

  const handleSelectEmployee = (value) => {
    const employee = employees.find(emp => emp._id === value);
    if (employee) {
      setEmployeeFormData(employee);
      setSelectedEmployeeId(employee._id);
      setFormData({
        ...formData,
        name: employee.name,
        basicSalary: employee.basicSalary,
        workingDays: employee.workDays,
        employeeId: employee._id,
        mobileNumber: employee.mobileNumber,
        email: employee.email,
      });
    }
  };

  const handleAddEmployee = () => {
    setModalOpened(true);
    setEmployeeFormData({ _id: null, name: "", basicSalary: "", workDays: "" });
    setSelectedEmployeeId(null);
  };

  const handleEmployeeSubmit = async (event) => {
    event.preventDefault();
    const method = employeeFormData._id ? "put" : "post";
    const url = employeeFormData._id ? `${baseUrl}/employees/${employeeFormData._id}` : `${baseUrl}/employees`;

    try {
      await axios[method](url, employeeFormData);
      toast.success(`Employee ${employeeFormData._id ? 'updated' : 'added'} successfully!`);
      fetchEmployees();
      setModalOpened(false);
      setSelectedEmployeeId(null);
      setEmployeeFormData({ _id: null, name: "", basicSalary: "", workDays: "" });
    } catch (error) {
      toast.error('Failed to submit employee data');
    }
  };

  const handleEmployeeEdit = (employee) => {
    setEmployeeFormData(employee);
  };

  const handleEmployeeDelete = async (id) => {
    try {
      await axios.delete(`${baseUrl}/employees/${id}`);
      toast.success('Employee deleted successfully');
      fetchEmployees();
    } catch (error) {
      toast.error('Failed to delete employee');
    }
  };

  const resetForm = () => {
    setEmployeeFormData({ name: "", basicSalary: "", workDays: "" });
  };

  const sendNDA = (email) => {
    setEmailData({
      to: email,
      subject: 'Action Required: Deltabits Non-Disclosure Agreement (NDA)',
      text: `Dear [Recipient's Name],

We hope this message finds you well.

Please review and sign the Non-Disclosure Agreement (NDA) by clicking on the link below:
https://form.jotform.com/241831668581464

Your prompt attention to this matter is greatly appreciated.

Best regards,
Deltabits Management`
    });
  };


  const [emailData, setEmailData] = useState({
    to: '',
    subject: '',
    html: '',
  });

  const handleEmailSubmit = async (e, data) => {
    e.preventDefault();
    const emailDatas = {
      to: data.email,
      subject: 'Deltabits Non-Disclosure Agreement (NDA)',
      html: `
     <!DOCTYPE html>
<html>
<head>
    <style>
        body {
            font-family: Arial, sans-serif;
            background: linear-gradient(135deg, #0063B1, #9CC2D5);
            margin: 0;
            padding: 0;
            color: #333;
        }
        .container {
            width: 80%;
            max-width: 600px;
            margin: 0 auto;
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .header {
            text-align: center;
            padding: 10px 0;
            border-bottom: 1px solid #ddd;
        }
        .header h1 {
            margin: 0;
            font-size: 24px;
            color: #4CAF50;
        }
        .content {
            padding: 20px;
        }
        .content p {
            line-height: 1.6;
            color: #333;
        }
        .button {
            display: inline-block;
            padding: 10px 20px;
            background-color: #4CAF50;
            color: white;
            text-decoration: none;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.2);
            margin-top: 20px;
        }
        .footer {
            text-align: center;
            padding: 10px 0;
            border-top: 1px solid #ddd;
            margin-top: 20px;
            color: #999;
            font-size: 12px;
        }
        .logo {
            text-align: center;
            margin-bottom: 20px;
        }
        .logo img {
            max-width: 100px; /* Adjust size as needed */
            height: auto;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="logo">
            <img src="https://i.ibb.co/CsrqsyS/final-1.png" alt="Company Logo">
            <h1 style="color: #4CAF50;">Deltabits Management</h1>
        </div>
        <div class="content">
            <p>Dear ${data.name},</p>
            <p>We hope this message finds you well.</p>
            <p>Please review and sign the Non-Disclosure Agreement (NDA) by clicking on the button below:</p>
            <a href="https://form.jotform.com/241831668581464" class="button">Sign NDA</a>
            <p>Your prompt attention to this matter is greatly appreciated.</p>
            <p>Best regards,<br/>Deltabits Management</p>
        </div>
        <div class="footer">
            <p>This is an automated message, please do not reply.</p>
        </div>
    </div>
</body>
</html>

      `,
    };
    try {
      const response = await axios.post(`${baseUrl}/send-email`, emailDatas);
      console.log(response.data);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };


  return (
    <>
      <Toaster />
      <AppBar position="static" color="inherit" className={classes.appBar}>
        <Toolbar>
          <Image
            src={Logo}
            alt="Logo"
            radius="md"
            className={classes.logo}
          />
          <a href="/" rel="noreferrer"
            style={{ textDecoration: 'none', color: 'black' }}
            className={classes.title}
          >
            <Typography variant="h6" className={classes.title}>
              Deltabits PaySlip
            </Typography>
          </a>
          {!modalOpened && (
          <Button
            color="green"
            variant="outline"
            mr={5}
            onClick={handleAddEmployee}
          >
            Add Employee
            </Button>
      )}
          <Select
            placeholder="Filter by Month"
            data={monthOptions}
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{ width: 200, marginRight: 20 }}
            rightSection={<IoIosArrowDown />}
          />
          <Text className={classes.greenBox}>
            Total&nbsp;Salaries:&nbsp;{`${filteredSalaries.reduce((total, salary) => total + salary.finalSalary, 0).toFixed(2)}`}
          </Text>
        </Toolbar>
      </AppBar>
      <Grid
        style={{ padding: 10 }}
        align="center"
        justify="center"
        spacing="xs"
      >
        <Grid.Col span={{ base: 11, md: 6, lg: 5 }}>
          <Paper style={{ padding: 20 }} shadow="xs" radius="lg" >
            <Typography variant="h6">Salary Form</Typography>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Group grow>
                <Select
                  label="Select Employee"
                  placeholder="Select an employee"
                  value={selectedEmployeeId || formData.name}
                  rightSection={<IoIosArrowDown />}
                  onChange={handleSelectEmployee}
                  data={employees.map(emp => ({ value: emp._id, label: emp.name }))}
                  searchable
                  clearable
                  onClear={() => {
                    setSelectedEmployeeId(null);
                    setEmployeeFormData({ _id: null, name: "", basicSalary: "", workDays: "" });
                    setFormData({
                      _id: '',
                      name: "",
                      month: new Date(),
                      email: "",
                      basicSalary: '',
                      workingDays: '',
                      presentFullDays: '',
                      presentHalfDays: '',
                      totalPresentDays: '',
                      salaryPerDay: '',
                      advancedSalary: '',
                      incentive: '',
                      mobileNumber: "",
                      finalSalary: calculateFinalSalary()
                    });
                  }}
                />
                <DateInput
                  label="Month"
                  name="month"
                  error={errors.month}
                  value={new Date(formData.month) || new Date()}
                  onChange={(e) => {
                    // Ensure e is a Date object
                    const newDate = new Date(e);
                    if (!isNaN(newDate)) {
                      handleFormChange({ target: { name: "month", value: newDate } });
                    }
                  }}
                />
                <TextInput
                  type="number"
                  error={errors.basicSalary}
                  label="Basic Salary"
                  name="basicSalary"
                  value={formData.basicSalary}
                  onChange={handleFormChange}
                />
              </Group>
              <Group grow>
                <TextInput
                  type="number"
                  label="Working Days"
                  name="workingDays"
                  error={errors.workingDays}
                  value={formData.workingDays}
                  onChange={handleFormChange}
                />
                <TextInput
                  type="number"
                  label="Present Full Days"
                  name="presentFullDays"
                  error={errors.presentFullDays}
                  value={formData.presentFullDays}
                  onChange={handleFormChange}
                />
                <TextInput
                  type="number"
                  label="Present Half Days"
                  name="presentHalfDays"
                  error={errors.presentHalfDays}
                  value={formData.presentHalfDays}
                  onChange={handleFormChange}
                />
              </Group>
              <Group grow>
                <TextInput
                  type="number"
                  label="Total Present Days"
                  name="totalPresentDays"
                  error={errors.totalPresentDays}
                  value={handleCalculateTotalPresentDays()}
                  onChange={handleFormChange}
                  disabled
                />
                <TextInput
                  type="number"
                  label="Salary per Day"
                  name="salaryPerDay"
                  error={errors.salaryPerDay}
                  value={calculateSalaryPerDay()}
                  onChange={handleFormChange}
                  disabled
                />
                <TextInput
                  type="number"
                  label="Advanced Salary"
                  name="advancedSalary"
                  error={errors.advancedSalary}
                  value={formData.advancedSalary}
                  onChange={handleFormChange}
                />
                <TextInput
                  type="number"
                  label="Incentive"
                  name="incentive"
                  error={errors.incentive}
                  value={formData.incentive}
                  onChange={handleFormChange}
                />
              </Group>
              <Group grow>
                <TextInput
                  label="Mobile Number"
                  name="mobileNumber"
                  error={errors.mobileNumber}
                  value={formData.mobileNumber}
                  onChange={handleFormChange}
                />
                <TextInput
                  type="email"
                  label="Email"
                  name="email"
                  error={errors.email}
                  value={formData.email}
                  onChange={handleFormChange}
                />
                <TextInput
                  type="number"
                  label="Final Salary"
                  name="finalSalary"
                  error={errors.finalSalary}
                  value={calculateFinalSalary()}
                  onChange={handleFormChange}
                  disabled
                />
              </Group>
              <div>
                <Button
                  type="submit"
                  color={formData._id ? "blue" : "green"}
                  variant="contained"
                  style={{ marginTop: 20 }}
                >
                  {formData._id ? "Update" : "Add"}
                </Button>
                <Button
                  onClick={() => {
                    setFormData({
                      _id: '',
                      name: "",
                      month: new Date(),
                      email: "",
                      basicSalary: '',
                      workingDays: '',
                      presentFullDays: '',
                      presentHalfDays: '',
                      totalPresentDays: '',
                      salaryPerDay: '',
                      advancedSalary: '',
                      incentive: '',
                      mobileNumber: "",
                      finalSalary: calculateFinalSalary(),
                    });
                    setSelectedSalary('');
                  }}
                  color="red"
                  variant="contained"
                  style={{ marginTop: 20, marginLeft: 10 }}
                >
                  Clear
                </Button>
              </div>
            </form>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
          <Paper
            style={{
              padding: 20,
              height: "375px",
              overflowY: "auto",
            }}
            shadow="xs" radius="lg"
          >
            <Typography variant="h6">Payslip Preview</Typography>
            <Typography style={{ whiteSpace: "pre-wrap" }}>
              {generateMessage(selectedSalary)}
            </Typography>
          </Paper>
        </Grid.Col>
      </Grid>
      <Table component={ScrollArea}
        style={{ marginTop: 10, padding: 10 }}
        styles={{
          td: {
            textAlign: 'center',
            padding: '10px',
          }
        }}
      >
        <Table striped highlightOnHover withTableBorder withColumnBorders >
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Month</Table.Th>
              <Table.Th>Mobile Number</Table.Th>
              <Table.Th>Basic Salary</Table.Th>
              <Table.Th>Final Salary</Table.Th>
              <Table.Th>Working Days</Table.Th>
              <Table.Th>Present Full Days</Table.Th>
              <Table.Th>Present Half Days</Table.Th>
              <Table.Th>Total Present Days</Table.Th>
              <Table.Th>Salary per Day</Table.Th>
              <Table.Th>Advanced Salary</Table.Th>
              <Table.Th>Incentive</Table.Th>
              <Table.Th>Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {filteredSalaries.map((salary) => (
              <Table.Tr
                Rowkey={salary._id}
                hover
                onClick={() => {}}
              >
                <Table.Td>{salary.name}</Table.Td>
                <Table.Td>{salary.email}</Table.Td>
                <Table.Td>
                  {moment(salary.month).format("MMMM YYYY")}
                </Table.Td>
                <Table.Td>{salary.mobileNumber}</Table.Td>
                <Table.Td>{salary.basicSalary}</Table.Td>
                <Table.Td
                  style={{
                    color:
                      salary.finalSalary < salary.basicSalary
                        ? "red"
                        : "green",
                  }}
                >
                  {Math.round(salary.finalSalary)}
                </Table.Td>
                <Table.Td>{salary.workingDays}</Table.Td>
                <Table.Td>{salary.presentFullDays}</Table.Td>
                <Table.Td>{salary.presentHalfDays}</Table.Td>
                <Table.Td>{salary.totalPresentDays}</Table.Td>
                <Table.Td>{salary.salaryPerDay}</Table.Td>
                <Table.Td>{salary.advancedSalary}</Table.Td>
                <Table.Td
                  styles={{
                    td: {
                      color: salary.incentive > 0 ? "green" : "red",
                      backgroundColor: salary.incentive > 0 ? "rgba(0, 128, 0, 0.1)" : "rgba(255, 0, 0, 0.1)",
                    }
                  }}
                >{salary.incentive}</Table.Td>
                <Table.Td>
                  <Button
                    style={{ marginRight: 5 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(salary);
                      handleSelectSalary(salary)
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    style={{ marginRight: 5 }}
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(salary._id);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    color="green"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleWhatsAppSend(salary);
                    }}
                  >
                    <SiWhatsapp />
                  </Button>
                  <Tooltip label="Send NDA" position="left">
                    <Button
                      ml={5}
                      onClick={(e) => {
                        e.stopPropagation();
                        // setEmailData({
                        //   to: salary.email,
                        //   subject: `Payslip for ${moment(salary.month).format("MMMM YYYY")}`,
                        //   html: generateMessage(salary),
                        // });
                        handleEmailSubmit(e, salary);
                      }}
                    >
                      <MdOutlineEmail />
                    </Button>
                  </Tooltip>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Table>
      <EmployeeModal
        opened={modalOpened}
        setOpened={setModalOpened}
        employees={employees}
        formData={employeeFormData}
        setFormData={setEmployeeFormData}
        handleSubmit={handleEmployeeSubmit}
        handleEdit={handleEmployeeEdit}
        handleDelete={handleEmployeeDelete}
        resetForm={resetForm}
      />
    </>
  );
}

export default App;
