import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import { MantineProvider} from '@mantine/core';
import LoginModal from './LoginModal.js';
import TwoFactorAuth from './mfa.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MantineProvider>
      {/* <TwoFactorAuth/> */}
    <LoginModal />
      <App />
    </MantineProvider>
  </React.StrictMode>
);
